import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { createChart, CrosshairMode } from 'lightweight-charts';
import axios from "axios";
import './styles.css';

function App() {

  const chartContainerRef = useRef();
  const chart = useRef();
  const resizeObserver = useRef();

  useEffect(() => {
 
  const queryParams = new URLSearchParams(window.location.search);
  const baseCurrency = queryParams.get('currency');
  const quoteCurrency = queryParams.get('quoteCurrency');
  
  // const timestamp = queryParams.get('timestamp');
  
  const interval = queryParams.get('interval');

  let timestamp=Math.floor(Date.now());
 
  // let myUrlStr = "https://node1.ledgerbytes.tech/market/get-chart-data?baseCurrency="+baseCurrency+"&quoteCurrency="+quoteCurrency+"&timestamp="+timestamp+"&interval="+interval+"&limit=1000";
 
  let myUrlStr = "https://node1.kriptrade.com/market/get-chart-data?baseCurrency="+baseCurrency+"&quoteCurrency="+quoteCurrency+"&timestamp="+timestamp+"&interval="+interval+"&limit=1000";
 

  const getDecimalPrecision = (number) => {
    // let precision = 0;
    // let e = 1;
    // if (!isFinite(number)) {
    //   return precision;
    // }
    // while (Math.round(e * number) / e !== number) {
    //   e *= 10;
    //   precision++;
    // }
    // return precision;

    let strNum = number.toString();
    let strNumArr = strNum.split(".")

    if (strNumArr[1]) {
      return strNumArr[1].length;
    } else {
      return 0;
    }
      
  };

  axios.get(myUrlStr).then((response) => { 

    let newValues = response.data.data;
    let oldValues = response.data.data; 
    for (let i=0; i<oldValues.length; i++) {
      newValues[i].time = (oldValues[i].time/1000) + 10800; // 10800 => 3H
      console.log(newValues[i].time);
    } 

    let myPrecision = getDecimalPrecision(newValues[0].open);
 
                          chart.current = createChart(chartContainerRef.current, {
                            width: chartContainerRef.current.clientWidth,
                            height: chartContainerRef.current.clientHeight,
                            layout: {
                              backgroundColor: '#eee',
                              textColor: 'rgba(155,155,155, 1)',
                            },  
                            grid: {
                              vertLines: {
                                color: '#aaa',
                              },
                              horzLines: {
                                color: '#aaa',
                              },
                            }, 
                          localization: {
                            locale: 'tr-TR'
                          },
                            crosshair: {
                              mode: CrosshairMode.Normal,
                             
                            }, 
                            timeScale: {
                              timeVisible: true,
                              secondsVisible: false, 
                            }, 
                            priceScale: {
                              borderColor: '#888',
                            }, 
                          });

                          const candleSeries = chart.current.addCandlestickSeries({
                            upColor: '#26BF22',
                            downColor: '#DB2327',
                            borderDownColor: '#DB2327',
                            borderUpColor: '#26BF22',
                            wickDownColor: '#7F323F',
                            wickUpColor: '#326853',
                          });
                       
                          candleSeries.applyOptions({
                            timezone: "Europe/Istanbul", 
                            priceFormat: {
                                type: 'custom',
                                minMove: 0.002,
                                // formatter: price => '$' + price.toFixed(3),
                                // formatter: price => price.toFixed(3),
                                formatter: price => price.toFixed(myPrecision)
                            },
                          });
 
                          candleSeries.setData(newValues); 
    });

  }, []);

  // Resize chart on container resizes.
  useEffect(() => {
    resizeObserver.current = new ResizeObserver(entries => {
      const { width, height } = entries[0].contentRect;
      chart.current.applyOptions({ width, height });
      setTimeout(() => {
          // chart.current.timeScale().fitContent();
      }, 0);
    });

    resizeObserver.current.observe(chartContainerRef.current);

    return () => resizeObserver.current.disconnect();
  }, []);

  return (
    <div className="App">
      <div ref={chartContainerRef} className="chart-container" />
    </div>
  );
}

const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
